<div [id]="id" class="card" [@cardClose]="cardClose" [ngClass]="cardClass">
  <div class="card-header" *ngIf="title">
    <div class="card-header-left d-flex align-items-center">
      <h5 style="margin-top: 1px">{{ title }}</h5>
      <button *ngIf="btnLimparFiltros" class="btn btn-mini btn-info btn-round" style="cursor: pointer;" (click)="clickBtn()">
        <i class="ti-brush-alt mr-1"></i> Limpar Filtros
      </button>
      <span *ngIf="!classHeader && headerContent">{{ headerContent }}</span>
      <span *ngIf="classHeader">
        <ng-content select=".code-header"></ng-content>
      </span>
    </div>
    <div class="card-header-right" style="z-index: 1;">
      <span style="color: #9f9f9f" *ngIf="subtitle" class="code-header" [ngbTooltip]="subtitle.tooltip">
        <i *ngIf="subtitle.icon" [class]="subtitle.icon"></i>
        {{subtitle.text}}
        <i *ngIf="subtitle.tooltip" class="fa fa-question-circle help-icon ml-1"></i>
      </span>
      <i class="ti-help-alt helpIcon" (click)="clickHelp()" *ngIf="tooltipText" [ngbTooltip]="tooltipText" placement="left"></i>
      <i class="ti-help-alt helpIcon" (click)="clickHelp()" *ngIf="showHelp" ngbTooltip="Precisa de Ajuda?"
        placement="left"></i>
      <i class="icofont icofont-rounded-down" [ngClass]="{'icon-up': startCollapsed}" *ngIf="showMinimize" cardToggleEvent (click)="toggleCard()"></i>
      <i class="icofont icofont-refresh" *ngIf="showLoading" (click)="clickLoading()" [ngbPopover]="popLoading" container="body" triggers="mouseenter:mouseleave" #p="ngbPopover" ></i>
      <i class="icofont icofont-close-circled" (click)="closeCard()" *ngIf="showClose"></i>
    </div>
  </div>

  <div [@cardToggle]="cardToggle">
    <div class="card-body" [ngClass]="blockClass">
      <ng-content></ng-content>
    </div>
  </div>
</div>