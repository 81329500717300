<app-modal-basic #modalFaturamentoDetalhado dialogClass="modal-lg" (closed)="onClose()">
  <div class="app-modal-header">
    <h4 class="modal-title">Faturamento Detalhado</h4>
    <button type="button" class="close basic-close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <app-loading [loading]="loading['dados']" *ngIf="loading['dados']" [icone]="'icofont icofont-refresh'">
    </app-loading>

    <p *ngIf="!loading['dados'] && !faturamentoDetalhado">Falha ao carregar os detalhes.</p>

    <ng-container *ngIf="!loading['dados'] && faturamentoDetalhado">
      <ng-container *ngIf="faturamentoDetalhado.cfopsConsideradas && faturamentoDetalhado.cfopsConsideradas.length > 0">
        <app-faturamento-detalhado-collapse>
          <ng-container title>
            <strong>CFOPs consideradas</strong>: {{faturamentoDetalhado.cfopsConsideradas.length}}
            <i ngbTooltip="CFOPs utilizadas no período e configuradas para gerar faturamento."
              container="body" class="ml-1 fa fa-info-circle"></i>
          </ng-container>
  
          <div class="row">
            <div class="col-2" *ngFor="let cfop of faturamentoDetalhado.cfopsConsideradas">{{cfop}}</div>
          </div>
        </app-faturamento-detalhado-collapse>
        <hr>
      </ng-container>
      <div *ngIf="faturamentoDetalhado.cfopsNaoConsideradas && faturamentoDetalhado.cfopsNaoConsideradas.length > 0" class="mt-2">
        <app-faturamento-detalhado-collapse>
          <ng-container title>
            <strong>CFOPs não consideradas</strong>: {{faturamentoDetalhado.cfopsNaoConsideradas.length}}
            <i ngbTooltip="CFOPs utilizadas no período e configuradas para não gerar faturamento."
              container="body" class="ml-1 fa fa-info-circle"></i>
          </ng-container>

          <div class="row">
            <div class="col-2" *ngFor="let cfop of faturamentoDetalhado.cfopsNaoConsideradas">{{cfop}}</div>
          </div>
        </app-faturamento-detalhado-collapse>
        <hr>
      </div>

      <p *ngIf="faturamentoDetalhado.faturamento && faturamentoDetalhado.faturamento.length === 0">
        Não foram encontrados registros no período.
      </p>
      <div *ngFor="let porMesAno of faturamentoDetalhado.faturamento" class="mt-2">
        <h5 class="mb-2" style="color: var(--base-text-color)">{{porMesAno.mesAno}}</h5>

        <app-faturamento-detalhado-collapse *ngFor="let porTipo of porMesAno.valores">
          <ng-container title>
            <strong>{{porTipo.tipo}}</strong>: {{porTipo.total | currency:'BRL':'symbol':'1.2-2'}}
          </ng-container>

          <app-dim-datatable [showInfoMobile]="false"
            [data]="porTipo.valores" [columns]="colunas"
            [rowsOnPage]="10" [serverPaginated]="false"
            [totalItems]="porTipo.valores.length" [acoes]="false">
          </app-dim-datatable>
        </app-faturamento-detalhado-collapse>
        <hr>
      </div>
    </ng-container>
  </div>
</app-modal-basic>