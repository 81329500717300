<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" textHeader="Processos de Importação" modalContentId="modal-processos-de-importacao" customClass="modal-lg">
  <form [formGroup]="form">
    <div class="row">
      <div class="form-group col-lg-8">
        <label>Período de</label>
        <div class="input-group">
          <app-data [calendarPosition]="'left'" formControlName="dataInicio"></app-data>
          <span class="input-group-addon">até</span>
          <app-data formControlName="dataFim"></app-data>
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="form-group col">
        <div>
          <label>Agrupar por</label>
        </div>
        <div class="form-radio form-control" style="border: unset; padding-left: 0px;">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="tipoVisualizacao" value="M">
              <i class="helper"></i><span>Sem agrupamento</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="tipoVisualizacao" value="P">
              <i class="helper"></i><span>Por Processo</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="tipoVisualizacao" value="C">
              <i class="helper"></i><span>Por Cliente</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="tipoVisualizacao" value="CP">
              <i class="helper"></i><span>Por Cliente/Processo</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="['M', 'P'].includes(form.get('tipoVisualizacao').value)" [@fadeInOut]>
      <div class="form-group col" rem5 combobox [type]="'cliente'" formControlName="clienteContratanteID"
        [label]="'Cliente Contratante'" [form]="form" appendTo="#modal-processos-de-importacao">
      </div>
    </div>

    <div class="row mb-2" *ngIf="['P', 'C', 'CP'].includes(form.get('tipoVisualizacao').value)" [@fadeInOut]>
      <div class="col">
        <app-dim-checkbox label="Suprimir Saldos Zerados" formControlName="suprimirSaldosZerados"></app-dim-checkbox>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <button type="button" class="btn btn-primary ripple" (click)="consultar()">
          <i class="icofont icofont-refresh"></i> Consultar
        </button>
        <button type="button" class="btn btn-info ripple" (click)="imprimir()" [disabled]="loading['imprimir']">
          <app-loading [loading]="loading['imprimir']" [icone]="'fa fa-print'" texto="Imprimir"></app-loading>
        </button>
      </div>
    </div>
  </form>

  <div *ngIf="loading['consulta']">
    <div class="row flex justify-content-center mt-2">
      <i class="fa fa-circle-o-notch fa-spin fa-lg"></i>
    </div>
  </div>

  <hr *ngIf="!loading['consulta'] && !dados?.length" />

  <!-- Sem Agrupamento -->
  <div class="row" *ngIf="form.get('tipoVisualizacao').value === 'M' && !loading['consulta'] && dados?.length">
    <div class="col">
      <app-dim-datatable [showInfoMobile]="false"
        [data]="dados" [columns]="colunasSemAgrupamento"
        [rowsOnPage]="10" [serverPaginated]="false"
        [totalItems]="dados.length" [acoes]="false">
      </app-dim-datatable>
    </div>
  </div>

  <!-- Por Processo -->
  <div class="row" *ngIf="form.get('tipoVisualizacao').value === 'P' && !loading['consulta'] && dados?.length">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th>Processo</th>
            <th>Cliente Contratante</th>
            <th>Saldo</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let grupo of dados">
            <tr>
              <td class="coluna-ver-detalhes" (click)="grupo.verDetalhes = !grupo.verDetalhes">
                <i class="fa" [class.fa-minus]="grupo.verDetalhes" [class.fa-plus]="!grupo.verDetalhes"></i>
              </td>
              <td>{{ grupo.processoImportacaoNome }}</td>
              <td>{{ grupo.clienteContratanteRazaoSocial }}</td>
              <td>{{ grupo.saldo | currency:'BRL':'symbol':'1.2-2' }}</td>
            </tr>
            <tr *ngIf="grupo.verDetalhes" [@fadeInOut]>
              <td></td>
              <td colspan="3">
                <app-dim-datatable [showInfoMobile]="false"
                  [data]="grupo.movimentacoes" [columns]="colunasAgrupamentoPorProcesso"
                  [rowsOnPage]="10" [serverPaginated]="false"
                  [totalItems]="grupo.movimentacoes.length" [acoes]="false">
                </app-dim-datatable>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Por Cliente -->
  <div class="row" *ngIf="form.get('tipoVisualizacao').value === 'C' && !loading['consulta'] && dados?.length">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th>Cliente Contratante</th>
            <th>Saldo</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let grupo of dados">
            <tr>
              <td class="coluna-ver-detalhes" (click)="grupo.verDetalhes = !grupo.verDetalhes">
                <i class="fa" [class.fa-minus]="grupo.verDetalhes" [class.fa-plus]="!grupo.verDetalhes"></i>
              </td>
              <td>{{ grupo.clienteContratanteRazaoSocial }}</td>
              <td>{{ grupo.saldo | currency:'BRL':'symbol':'1.2-2' }}</td>
            </tr>
            <tr *ngIf="grupo.verDetalhes" [@fadeInOut]>
              <td></td>
              <td colspan="3">
                <app-dim-datatable [showInfoMobile]="false"
                  [data]="grupo.movimentacoes" [columns]="colunasAgrupamentoPorCliente"
                  [rowsOnPage]="10" [serverPaginated]="false"
                  [totalItems]="grupo.movimentacoes.length" [acoes]="false">
                </app-dim-datatable>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Por Cliente/Processo -->
  <div class="row" *ngIf="form.get('tipoVisualizacao').value === 'CP' && !loading['consulta'] && dados?.length">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th>Cliente Contratante</th>
            <th>Saldo</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let grupoCliente of dados">
            <tr>
              <td class="coluna-ver-detalhes" (click)="grupoCliente.verDetalhes = !grupoCliente.verDetalhes">
                <i class="fa" [class.fa-minus]="grupoCliente.verDetalhes" [class.fa-plus]="!grupoCliente.verDetalhes"></i>
              </td>
              <td>{{ grupoCliente.clienteContratanteRazaoSocial }}</td>
              <td>{{ grupoCliente.saldo | currency:'BRL':'symbol':'1.2-2' }}</td>
            </tr>
            <tr *ngIf="grupoCliente.verDetalhes" [@fadeInOut]>
              <td></td>
              <td colspan="3">

                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Processo</th>
                      <th>Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let grupoProcesso of grupoCliente.processos">
                      <tr>
                        <td class="coluna-ver-detalhes" (click)="grupoProcesso.verDetalhes = !grupoProcesso.verDetalhes">
                          <i class="fa" [class.fa-minus]="grupoProcesso.verDetalhes" [class.fa-plus]="!grupoProcesso.verDetalhes"></i>
                        </td>
                        <td>{{ grupoProcesso.processoImportacaoNome }}</td>
                        <td>{{ grupoProcesso.saldo | currency:'BRL':'symbol':'1.2-2' }}</td>
                      </tr>
                      <tr *ngIf="grupoProcesso.verDetalhes" [@fadeInOut]>
                        <td></td>
                        <td colspan="3">
                          <app-dim-datatable [showInfoMobile]="false"
                            [data]="grupoProcesso.movimentacoes" [columns]="colunasAgrupamentoPorProcesso"
                            [rowsOnPage]="10" [serverPaginated]="false"
                            [totalItems]="grupoProcesso.movimentacoes.length" [acoes]="false">
                          </app-dim-datatable>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>

              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <button type="button" class="btn btn-info ripple" (click)="modalCadastro.hide()">
        <i class="ti ti-close"></i> Fechar
      </button>
    </div>
  </div>
</app-temp-modal-cadastro>
