<app-temp-modal-cadastro #modalCadastro modalContentId="modal-representante" (closed)="onClose()" [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-8">
        <label for="form_nome" class="label-required">Nome</label>
        <input id="form_nome" [disableControl]="loading['registro']" type="text" class="form-control" formControlName="nome" />
        <show-errors [control]="cadastroForm.controls.nome"></show-errors>
      </div>

      <div class="form-group col-sm-4">
        <label for="form_codigo" class="label-required">Código</label>
        <input id="form_codigo" appAutofocus [disableControl]="loading['registro']" type="text" class="form-control" digitOnly
          formControlName="codigo" />
        <show-errors [control]="cadastroForm.controls.codigo"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12" combobox rem5 [type]="'tipo-comissao'" [label]="'Comissao Vendedor'"
        [loading]="loading" [form]="cadastroForm" formControlName="tipoComissaoVendedor" [modalCadastro]='true'
        [disabled]="loading['registro']" [appendTo]="'#modal-representante'">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12" >
        <app-dim-checkbox label="Preposto Vinculado" formControlName="preposto" (change)="handleOnChangePrepostoCheckbox($event)"></app-dim-checkbox>
        <div>
          <ng-select id="form_prepostoId" appendTo="#modal-representante"
            [disableControl]="loading['registro'] || cadastroForm.get('preposto').value" [loading]="loading['estados']"
            placeholder="Selecione" [items]="prepostos" bindLabel="nome" bindValue="id" formControlName="prepostoId"
            (change)="handleOnChangePreposto($event)">
          </ng-select>
          <show-errors [control]="cadastroForm.controls.prepostoId"></show-errors>
        </div>
      </div>
      <div class="form-group col-sm-12"
        combobox rem5 [type]="'tipo-comissao'" [label]="'Comissao Preposto'" [loading]="loading" [form]="cadastroForm"
        formControlName="tipoComissaoPreposto" [modalCadastro]='true' [disabled]="loading['registro']"
        [appendTo]="'#modal-representante'" [disableControl]="!cadastroForm.get('prepostoId').value">
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>
