<app-temp-modal-cadastro #modalCadastro textHeader='Cadastrar Usuário' (closed)="onClose()">
      <div class="app-modal-body" *ngIf="cadastroForm">
        <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
          <div class="form-group">
            <label><b>Grupo</b></label>
            <select class="form-control" placeholder="Grupo" formControlName="gruposUsuariosId"
              [disableControl]="this.loading['request']">
              <option *ngFor="let grupo of gruposAtivos" value="{{grupo.id}}"> {{ grupo.nome }} </option>
            </select>
          </div>
          <div class="row">
            <div class="form-group col-sm-6">
              <label><b>Login</b></label>
              <input type="text" class="form-control" formControlName="login"
                [disableControl]="this.loading['request']">
                <span *ngIf="loginInvalido" class="text-danger error">{{loginInvalido}}</span>
            </div>
            <div class="form-group col-sm-6">
              <label ngbTooltip="Sua senha deve conter de 8 a 20 caracteres e, pelo menos, um número ou uma letra." placement="right">
                <b>Senha</b>
              </label>
              <app-input-senha [form]="cadastroForm" formControlName="senha" placeholder="Senha de acesso"
              [disabled]="this.loading['request']"></app-input-senha>
              <show-errors [control]="cadastroForm.controls['senha']"></show-errors>
              <span *ngIf="senhaInvalida" class="text-danger error">{{senhaInvalida}}</span>
            </div>
          </div>
          <div class="row">  
            <div class="form-group col-sm-12">
              <label><b>Confirmar Senha</b></label>
              <app-input-senha [form]="cadastroForm" formControlName="confirmarSenha" placeholder="Confirmar senha de acesso"
                [disabled]="this.loading['request']"></app-input-senha>
                <show-errors [control]="cadastroForm.controls['confirmarSenha']"></show-errors>
                <span *ngIf="erroSenha" class="text-danger error">{{erroSenha}}</span>
            </div>
          </div>

          <button class="btn btn-primary custom-form-button" > <i class="ti-save"> </i> Cadastrar
          </button>
          <button class="btn btn-info custom-form-button" (click)="modalCadastro.hide()" [disabled]="loading['request'] || loading['consulta']"
          type="button">
          <i class="ti ti-back-left"></i> Voltar
        </button>
        </form>
      </div>
      <div class="app-modal-footer">
      </div>
</app-temp-modal-cadastro>