<app-temp-modal-cadastro textHeader='Pesagem de volumes' #modalVolumes id="modalPesagemVolumes" (closed)="onClose()">

  <ng-container *ngIf="!editando">
    <div class="table-responsive" *ngIf="volumes.length > 0; else emptyList">
      <button type="button" (click)="adicionarVolume()" class="btn btn-sm btn-info ml-0 mb-2"><i class="fa fa-plus"></i>
        Adicionar volume</button>

      <table class="table table-striped">
        <thead>
          <tr>
            <th>Embalagem</th>
            <th>Peso embalagem</th>
            <th>Peso volume</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let volume of volumes; let index = index;">
            <td>{{volume.nomeEmbalagem}}</td>
            <td>{{volume.pesoEmbalagem | currency:'BRL':'':'1.3'}}</td>
            <td>{{volume.pesoVolume | currency:'BRL':'':'1.3'}}</td>
            <td style="width: 1%">
              <button type="button" class="btn btn-danger btn-mini waves-effect waves-light" placement="left"
                ngbTooltip="Excluir" (click)="excluirVolume(index)">
                <i class="fa fa-close"></i>
              </button>
              <button type="button" class="btn btn-info btn-mini waves-effect waves-light" placement="left"
                ngbTooltip="Editar" (click)="editarVolume(index)">
                <i class="fa fa-pencil-square-o"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button type="submit" (click)="finishPesagem()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'"></app-loading>
    </button>

    <button type="button" class="btn btn-default ripple" (click)="cancelarOperacao()">Cancelar</button>

    <ng-template #emptyList>
      <div> <span>Nenhum registro encontrado.</span>
        <button type="button" (click)="adicionarVolume()" class="btn btn-sm btn-info ml-2"><i class="fa fa-plus"></i>
          Adicionar volume</button>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="editando">
    <form [formGroup]="cadastroForm" (ngSubmit)="submitFormEmbalagem()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label class="font-weight-bold">Embalagem</label>
          <ng-select appendTo="#modalPesagemVolumes .modal-content" (change)="onEmbalagemChanged($event)"
            formControlName="embalagem" placeholder="Selecione" [items]="embalagens" bindLabel="nomeEmbalagem"
            bindValue="id" [loading]='loading["embalagens"]'>
          </ng-select>
          <show-errors [control]="cadastroForm.controls['embalagem']"></show-errors>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-6">
          <label class="font-weight-bold">Peso embalagem</label>
          <input type="text" [options]="{ prefix: '', precision: 3 }" currencyMask inputmode="numeric" formControlName="pesoEmbalagem"
            class="form-control" />
          <show-errors [control]="cadastroForm.controls['pesoEmbalagem']"></show-errors>
        </div>

        <div class="form-group col-sm-6">
          <label class="font-weight-bold">Peso volume</label>
          <input type="text" [options]="{ prefix: '', precision: 3 }" currencyMask inputmode="numeric" formControlName="pesoVolume"
            class="form-control" />
          <show-errors [control]="cadastroForm.controls['pesoVolume']"></show-errors>
        </div>
      </div>

      <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
        <app-loading [texto]="'OK'"></app-loading>
      </button>

      <button type="button" class="btn btn-default ripple" (click)="cancelaDigitacao()">Cancelar</button>
    </form>
  </ng-container>

</app-temp-modal-cadastro>