import { Component, ViewChild, AfterViewInit, forwardRef, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as textMask from "vanilla-text-mask/dist/vanillaTextMask.js";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';

@Component({
  selector: 'app-data',
  templateUrl: './app-data.component.html',
  styleUrls: ['./app-data.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppDataComponent),
      multi: true
    }
  ]
})
export class AppDataComponent implements AfterViewInit, ControlValueAccessor {

  date: Date;

  @Input() disabled: boolean = false;
  @Input() calendarPosition = 'right';
  @Input() calendarPlacement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() loading = false;
  @Input() id;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  writeValue(date: Date): void {
    if (date) {
      this.date = new Date(date);
    } else {
      this.date = null;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => { };

  onTouched = () => { };

  onChange = (event) => {
    if (moment(event).isValid() && this.isSqlServerDateValid(event)) {
      this.date = event;
    } else {
      this.date = null;
      this.renderer.setProperty(this.input.nativeElement, 'value', '');
    }
    this.propagateChange(this.date);
    this.change.emit();
  };

  isSqlServerDateValid(date) {
    return date >= moment('01-01-1753', 'DD-MM-YYYY').toDate();
  }

  constructor(private localeService: BsLocaleService, private renderer: Renderer2) {
    ptBrLocale.invalidDate = '';
    defineLocale('pt-br', ptBrLocale);
    this.localeService.use('pt-br');
  }

  bsConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: false,
    isAnimated: true,
  }

  mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  maskedInputController;

  @ViewChild("input", { static: false }) input: ElementRef;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maskedInputController = textMask.maskInput({
        inputElement: this.input.nativeElement,
        mask: this.mask
      });
    });
  }

  ngOnDestroy() {
    if (this.maskedInputController) {
      this.maskedInputController.destroy();
    }
  }
}
