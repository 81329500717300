<app-modal-basic #modalCadastro (closed)="onClose()" [allowClickOutside]="false" [modalContentId]="'modal-rv'">
  <div class="app-modal-header">
    <h4 class="modal-title">Lançar estoque</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="form-group col-sm-12" appendTo="#modal-rv" combobox rem5 [type]="'almoxarifado'" [loading]="loading"
          [form]="form" formControlName="almoxarifadoID" [modalCadastro]="true">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12" combobox appendTo="#modal-rv" rem5 [type]="'unidade-medida'"
          [loading]="loading" [form]="form" formControlName="unidadeMedidaID" [modalCadastro]="true">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12" combobox appendTo="#modal-rv" rem5 [type]="'motivo-mov-estoque'"
          [loading]="loading" [form]="form" formControlName="motivoMovimentacaoEstoqueID" [modalCadastro]="true">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Quantidade (saldo inicial)</label>
          <input class="form-control" type="number" formControlName="quantidade">
          <app-ajuste-numerico [form]="form" formControlName="quantidade"></app-ajuste-numerico>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button class="btn btn-primary float-right" (click)="confirmar()"><i class="ti-save"></i> Confirmar</button>
          <button class="btn btn-info" (click)="modalCadastro.hide()"><i class="ti ti-back-left"></i> Voltar</button>
        </div>
      </div>
    </form>
  </div>
</app-modal-basic>