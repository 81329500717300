import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from '../../../authentication/services/usuario.service';
import { AbrirModalComponent } from '../../../shared/abrir-modal/abrir-modal.component';
import { GruposUsuariosService } from '../../../services/grupos-usuarios.service';
import { UsuariosService } from '../../../services/usuarios.service';
import { GruposUsuarios } from '../../../model/GruposUsuarios';
import { lastValueFrom } from 'rxjs';
import { getErrorMessage, regexValidacaoSenha } from '@utils';
import { MessageService } from '@services';

@
Component({
  selector: 'app-cadastro-vendedor-usuario',
  templateUrl: './cadastro-vendedor-usuario.component.html',
  styleUrls: ['./cadastro-vendedor-usuario.component.css']
})
export class CadastroVendedorUsuarioComponent extends ModalCadastroComponent implements OnInit {

  constructor(private usuarioService: UsuariosService, private gpService: GruposUsuariosService, 
    private messageService: MessageService){
    super();
  }

  @ViewChild("modalCadastro", { static: true }) modalCadastro;
  @ViewChild('abrirModal', { static: true }) abrirModal: AbrirModalComponent;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  gruposAtivos: GruposUsuarios[] = [];
  grupos: GruposUsuarios[];
  loginInvalido = '';
  erroSenha = '';
  senhaInvalida = '';
  loading = []

  cadastroForm = new FormGroup({
    id: new FormControl(0),
    tipoCadastro: new FormControl(['M', Validators.required]),
    nome: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.pattern(this.emailPattern)]),
    gruposUsuariosId: new FormControl(null, [Validators.required]),
    administrador: new FormControl(false),
    insereVendedor: new FormControl(false),
    login: new FormControl(null),
    senha: new FormControl(null),
    confirmarSenha: new FormControl(null),
    inativo: new FormControl(false),
  });
  
  ngOnInit() {
    this.cadastroForm.get('nome').setValue(this.prop.nome);
    this.cadastroForm.get('email').setValue(this.prop.email);

    this.cadastroForm.get('login').setValue(this.prop.email);
    this.cadastroForm.get('senha').setValue(this.prop.telefone);

    this.gpService.getGruposCombo()
    .subscribe(r => {
      this.grupos = r;
      this.gruposAtivos = [];
      this.grupos.forEach(e => {
        if (!e.inativo) {

          this.gruposAtivos.push(e);
        }
      });
    });
  }

  show(): void {
    this.modalCadastro.show();
  }

  onClose(): void {
    this.closed.emit();
  }

  async submitForm(){
    if(!this.cadastroForm.valid){
      return;
    }

    if(!this.validaLoginSenha()) {
      return;
    };

    this.loading['request'] = true;
    try{
      this.usuarioService.create(this.cadastroForm.value).subscribe(r => {
        this.loading['request'] = false;
        this.messageService.success('Cadastro efetuado com sucesso!', 6000);
        this.modalCadastro.hide();
      });
    } catch (err){
      this.messageService.error('Falha ao salvar: ' + getErrorMessage(err), 8000);
    } finally{
      this.loading['request'] = false;
    }
  }

  async validaLoginSenha(){ 
    const login = this.cadastroForm.get('login').value;
    const res = await lastValueFrom(this.usuarioService.verificaLogin(this.id, login));
    if(!res.loginValido){
      this.loginInvalido = 'Login já está em uso!';
      return false;
    }

    const senha = this.cadastroForm.get('senha').value;
    const confirmarSenha = this.cadastroForm.get('confirmarSenha').value;
  
    if (senha != confirmarSenha) {
      this.erroSenha = 'Senha diferente da confirmação de senha!';
      return false;
    }

    if (!regexValidacaoSenha.test(senha) || !regexValidacaoSenha.test(confirmarSenha)) {
      this.senhaInvalida = `Sua senha não atende os requisitos necessários.
      - Não contenha os caracteres < > ' " / \ ; ( ) [ ] , =;
      - Inclua pelo menos um número;
      - Inclua pelo menos uma letra (maiúscula ou minúscula);
      - A senha deve ter entre 8 e 20 caracteres de comprimento.`

      return false;
    }
  }
}