<app-temp-modal-cadastro #modalConsultar textHeader="Importação Notas Fiscais" [customClass]="modalClass" (closed)="onClose()"
  [modalContentId]="'modal-importar-nfe'">
  <div class="app-modal-body">
    <ng-container *ngIf="modo === 'TXT_SEFAZ' && !showItensTable">
      <div class="row">
        <div class="col" #importarArquivo>
          <input (change)="selectFile($event.target.files)" class="file-input pointer-events-auto"
            style="display: none" type="file" accept=".txt">
          <div app-drag-and-drop (onFileDropped)="selectFile($event)" class="drag-space">
            <ng-container *ngIf="loading.arquivo">
              <i class="fa fa-circle-o-notch fa-spin" style="font-size: 20px"></i>
              <span class="mt-2 text-gray-800 text-sm">Carregando arquivo</span>
            </ng-container>
            <ng-container *ngIf="!loading.arquivo">
              <i class="fa fa-arrow-circle-down"></i>
              <ng-container *ngIf="arquivoSelecionado">
                <div class="d-flex flex-column drag-description" style="align-items: center">
                  <p class="mt-2 mb-1">Arquivo selecionado:</p>
                  <p class="mb-1">{{arquivoSelecionado}}</p>
                  <button type="button" (click)="filePicker()"
                    class="btn btn-primary btn-small pointer-events-auto mt-2 mr-0" style="text-transform: none">
                    Selecionar outro arquivo
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="!arquivoSelecionado">
                <div class="d-flex flex-column drag-description" style="align-items: center">
                  <p class="mt-2 mb-1">Arraste o TXT para cá</p>
                  <p class="mb-1" style="opacity: 0.5">ou</p>
                  <button type="button" (click)="filePicker()"
                    class="btn btn-primary btn-small pointer-events-auto mr-0" style="text-transform: none">
                    Selecione um arquivo
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="modo === 'NORMAL' && !showItensTable">
      <form [formGroup]="form">
        <div class="row">
          <div class="form-group col-sm-12">
            <label for="modal-importar-nfe-search">Pesquisar</label>
            <input id="modal-importar-nfe-search" type="text" class="form-control" formControlName="search" placeholder="Pesquisar">
          </div>
        </div>
      </form>

      <h4 *ngIf="!notasFiscais" class="text-center"><i class="fa fa-circle-o-notch fa-spin"></i></h4>
      <ng-container *ngIf="notasFiscais">
        <app-dim-datatable [loading]="loading['consulta']" [totalItems]="10" [data]="notasFiscais.items"
          [columns]="columns" [selectable]="true" [selecionados]="notasSelecionadas" (sorting)="onSortNotas($event)" [showPagination]="false">
        </app-dim-datatable>
      </ng-container>

      <button class="btn btn-primary" [disabled]="notasSelecionadas.length === 0 || loading['listarItens']" (click)="listarItens()">
        <app-loading [loading]="loading['listarItens']" texto="Próximo"></app-loading>
      </button>
    </ng-container>

    <ng-container *ngIf="showItensTable">
      <ng-container *ngIf="outrasSaidas">
        <div class="row">
          <div class="col">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>NCM</th>
                  <th>CST/CSOSN ICMS</th>
                  <th>Alíq. ICMS</th>
                  <th>CST IPI</th>
                  <th>Alíq. IPI</th>
                  <th>CST PIS</th>
                  <th>Alíq. PIS</th>
                  <th>CST COFINS</th>
                  <th>Alíq. COFINS</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ncm of alterarDadosNcms.ncms; let index = index">
                  <td>
                    {{ ncm.codigoNCM }}
                  </td>
                  <td class="coluna-cst-max-width p-1">
                    <ng-select [labelForId]="'ncm-0-' + index" appendTo="#modal-importar-nfe" [items]="cstICMS" [(ngModel)]="ncm.situacaoTributariaICMS"
                      bindLabel="descricao" bindValue="tipo" placeholder="Selecione"
                      (keydown)="moverFoco($event, 'ncm', 0, index, 7)" [keyDownFn]="desabilitarSetaParaBaixo"></ng-select>
                  </td>
                  <td class="coluna-aliquota-max-width p-1">
                    <input [id]="'ncm-1-' + index" class="form-control" type="text" currencyMask inputmode="numeric" (blur)="validarAliquotaDigitadaNCM(ncm, 'aliquotaICMS')"
                      [options]="currencyMaskOptionsAliquota" [(ngModel)]="ncm.aliquotaICMS"
                      placeholder="Alíq. ICMS" [readonly]="empresaCrt === 1" (keydown)="moverFoco($event, 'ncm', 1, index, 7)">
                  </td>
                  <td class="coluna-cst-max-width p-1">
                    <ng-select [labelForId]="'ncm-2-' + index" appendTo="#modal-importar-nfe" [items]="cstIPI" [(ngModel)]="ncm.situacaoTributariaIPI"
                      bindLabel="descricao" bindValue="tipo" placeholder="Selecione"
                      (keydown)="moverFoco($event, 'ncm', 2, index, 7)" [keyDownFn]="desabilitarSetaParaBaixo"></ng-select>
                  </td>
                  <td class="coluna-aliquota-max-width p-1">
                    <input [id]="'ncm-3-' + index" class="form-control" type="text" currencyMask inputmode="numeric" (blur)="validarAliquotaDigitadaNCM(ncm, 'aliquotaIPI')"
                      [options]="currencyMaskOptionsAliquota" [(ngModel)]="ncm.aliquotaIPI"
                      placeholder="Alíq. IPI" (keydown)="moverFoco($event, 'ncm', 3, index, 7)">
                  </td>
                  <td class="coluna-cst-max-width p-1">
                    <ng-select [labelForId]="'ncm-4-' + index" appendTo="#modal-importar-nfe" [items]="cstPIS" [(ngModel)]="ncm.situacaoTributariaPIS"
                      bindLabel="descricao" bindValue="tipo" placeholder="Selecione"
                      (keydown)="moverFoco($event, 'ncm', 4, index, 7)" [keyDownFn]="desabilitarSetaParaBaixo"></ng-select>
                  </td>
                  <td class="coluna-aliquota-max-width p-1">
                    <input [id]="'ncm-5-' + index" class="form-control" type="text" currencyMask inputmode="numeric" (blur)="validarAliquotaDigitadaNCM(ncm, 'aliquotaPIS')"
                      [options]="currencyMaskOptionsAliquota" [(ngModel)]="ncm.aliquotaPIS"
                      placeholder="Alíq. PIS" (keydown)="moverFoco($event, 'ncm', 5, index, 7)">
                  </td>
                  <td class="coluna-cst-max-width p-1">
                    <ng-select [labelForId]="'ncm-6-' + index" appendTo="#modal-importar-nfe" [items]="cstCOFINS" [(ngModel)]="ncm.situacaoTributariaCOFINS"
                      bindLabel="descricao" bindValue="tipo" placeholder="Selecione"
                      (keydown)="moverFoco($event, 'ncm', 6, index, 7)" [keyDownFn]="desabilitarSetaParaBaixo"></ng-select>
                  </td>
                  <td class="coluna-aliquota-max-width p-1">
                    <input [id]="'ncm-7-' + index" class="form-control" type="text" currencyMask inputmode="numeric" (blur)="validarAliquotaDigitadaNCM(ncm, 'aliquotaCOFINS')"
                      [options]="currencyMaskOptionsAliquota" [(ngModel)]="ncm.aliquotaCOFINS"
                      placeholder="Alíq. COFINS" (keydown)="moverFoco($event, 'ncm', 7, index, 7)">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <div rem5 combobox [type]="'codigos-fiscais-operacao'" [loading]="loading"
              appendTo="#modal-importar-nfe" (change)="mudouCFOP($event)" label="CFOP">
              <ng-template #descTmpl let-row="row">
                <span>{{row.codigo}} - {{row.descricao}}</span>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <app-dim-checkbox label="Descontar ICMS do PIS/COFINS" [(ngModel)]="alterarDadosNcms.abaterICMSBasePISCOFINS"></app-dim-checkbox>
          </div>
          <div class="col">
            <app-dim-checkbox label="Somar IPI na Base de Cálculo do ICMS" [(ngModel)]="alterarDadosNcms.somarIPIBaseICMS"></app-dim-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-combobox-cbenef-fiscais
              appendTo="#modal-importar-nfe"
              [(ngModel)]="alterarDadosNcms.codigoBeneficioFiscal"
              [notFoundPlusIcon]="false"
              [pencilIcon]="false"
              [customData]="{ cst: empresaCrt === 1 ? 'SIMPLES': null }"
            ></app-combobox-cbenef-fiscais>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <button type="button" class="btn btn-primary ripple" (click)="aplicarDados()" [disabled]="loading.aplicarDados">
              <app-loading texto="Aplicar" [loading]="loading.aplicarDados"></app-loading>
            </button>
          </div>
        </div>
      </ng-container>

      <div class="row mt-4">
        <div class="col">
          <app-dim-datatable [totalItems]="10" [showPagination]="false" [data]="itens" [columns]="columnsItens" [loading]="loading['consulta']"
            [selectable]="true" [selecionados]="itensSelecionados" [lyneStyle]="estiloLinhaItem.bind(this)" [containerStyle]="{}">
            <ng-template #customTmpl let-column="column" let-row="row" let-index="index" style="width: 1px; text-align: center;">
              <ng-template [ngIf]="column === 'descricaoProduto'">
                <div style="text-wrap: wrap; width: max-content; max-width: 320px">
                  {{row.descricaoProduto}}
                </div>
              </ng-template>
              <ng-template [ngIf]="column === 'quantidade'">
                <input [id]="'item-0-' + index" class="form-control" type="text" currencyMask inputmode="numeric" style="width: 100px"
                  [options]="{ prefix: '', thousands: '.', decimal: ',', precision: precisaoQtd, allowNegative: false }" [(ngModel)]="row.quantidade"
                  (ngModelChange)="calcularValorTotal(row)" (keydown)="moverFoco($event, 'item', 0, index, 9)">
              </ng-template>
              <ng-template [ngIf]="column === 'preco'">
                <input [id]="'item-1-' + index" class="form-control" type="text" currencyMask inputmode="numeric" style="width: 100px"
                  [options]="{ prefix: '', thousands: '.', decimal: ',', precision: precisaoPreco, allowNegative: false }" [(ngModel)]="row.preco"
                  (ngModelChange)="calcularValorTotal(row)" (keydown)="moverFoco($event, 'item', 1, index, 9)">
              </ng-template>
              <ng-template [ngIf]="column === 'situacaoTributariaICMS'">
                <ng-select [labelForId]="'item-2-' + index" style="width: 220px" appendTo="#modal-importar-nfe" [(ngModel)]="row.impostos.situacaoTributariaICMS" [items]="cstICMS"
                  bindLabel="descricao" bindValue="tipo" placeholder="Selecione"
                  (keydown)="moverFoco($event, 'item', 2, index, 9)" [keyDownFn]="desabilitarSetaParaBaixo"></ng-select>
              </ng-template>
              <ng-template [ngIf]="column === 'aliquotaICMS'">
                <input [id]="'item-3-' + index" *ngIf="empresaCrt === 1" class="form-control" type="text" currencyMask inputmode="numeric" style="width: 100px"
                  [options]="currencyMaskOptionsAliquota" [(ngModel)]="row.impostos.aliquotaCreditoICMS"
                  (blur)="validarAliquotaDigitada(row, 'aliquotaCreditoICMS')" readonly (keydown)="moverFoco($event, 'item', 3, index, 9)">
                <input [id]="'item-3-' + index" *ngIf="empresaCrt > 1" class="form-control" type="text" currencyMask inputmode="numeric" style="width: 100px"
                  [options]="currencyMaskOptionsAliquota" [(ngModel)]="row.impostos.aliquotaICMS"
                  (blur)="validarAliquotaDigitada(row, 'aliquotaICMS')" (keydown)="moverFoco($event, 'item', 3, index, 9)">
              </ng-template>
              <ng-template [ngIf]="column === 'situacaoTributariaIPI'">
                <ng-select [labelForId]="'item-4-' + index" style="width: 220px" appendTo="#modal-importar-nfe" [(ngModel)]="row.impostos.situacaoTributariaIPI" [items]="cstIPI"
                  bindLabel="descricao" bindValue="tipo" placeholder="Selecione"
                  (keydown)="moverFoco($event, 'item', 4, index, 9)" [keyDownFn]="desabilitarSetaParaBaixo"></ng-select>
              </ng-template>
              <ng-template [ngIf]="column === 'aliquotaIPI'">
                <input [id]="'item-5-' + index" class="form-control" type="text" currencyMask inputmode="numeric" style="width: 100px"
                  [options]="currencyMaskOptionsAliquota" [(ngModel)]="row.impostos.aliquotaIPI"
                  (blur)="validarAliquotaDigitada(row, 'aliquotaIPI')" (keydown)="moverFoco($event, 'item', 5, index, 9)">
              </ng-template>
              <ng-template [ngIf]="column === 'situacaoTributariaPIS'">
                <ng-select [labelForId]="'item-6-' + index" style="width: 220px" appendTo="#modal-importar-nfe" [(ngModel)]="row.impostos.situacaoTributariaPIS" [items]="cstPIS"
                  bindLabel="descricao" bindValue="tipo" placeholder="Selecione"
                  (keydown)="moverFoco($event, 'item', 6, index, 9)" [keyDownFn]="desabilitarSetaParaBaixo"
                  ></ng-select>
              </ng-template>
              <ng-template [ngIf]="column === 'aliquotaPIS'">
                <input [id]="'item-7-' + index" class="form-control" type="text" currencyMask inputmode="numeric" style="width: 100px"
                  [options]="currencyMaskOptionsAliquota" [(ngModel)]="row.impostos.aliquotaPIS"
                  (blur)="validarAliquotaDigitada(row, 'aliquotaPIS')" (keydown)="moverFoco($event, 'item', 7, index, 9)">
              </ng-template>
              <ng-template [ngIf]="column === 'situacaoTributariaCOFINS'">
                <ng-select [labelForId]="'item-8-' + index" style="width: 220px" appendTo="#modal-importar-nfe"
                  [(ngModel)]="row.impostos.situacaoTributariaCOFINS" [items]="cstCOFINS"
                  bindLabel="descricao" bindValue="tipo" placeholder="Selecione"
                  (keydown)="moverFoco($event, 'item', 8, index, 9)" [keyDownFn]="desabilitarSetaParaBaixo"
                  ></ng-select>
              </ng-template>
              <ng-template [ngIf]="column === 'aliquotaCOFINS'">
                <input [id]="'item-9-' + index" class="form-control" type="text" currencyMask inputmode="numeric" style="width: 100px"
                  [options]="currencyMaskOptionsAliquota" [(ngModel)]="row.impostos.aliquotaCOFINS"
                  (blur)="validarAliquotaDigitada(row, 'aliquotaCOFINS')" (keydown)="moverFoco($event, 'item', 9, index, 9)">
              </ng-template>
            </ng-template>
          </app-dim-datatable>
        </div>
      </div>
      <button class="btn btn-info" [disabled]="itensSelecionados.length === 0 || loading['importarItens'] || loading['dadosTransporte']" (click)="finalizar()">
        <app-loading [loading]="loading['importarItens'] || loading['dadosTransporte']" icone="ti ti-new-window" texto="Importar Itens"></app-loading>
      </button>
      <button class="btn btn-info btn-outline-info" (click)="voltar()">Voltar</button>
    </ng-container>
  </div>
  <div class="app-modal-footer">
  </div>
</app-temp-modal-cadastro>
