import { Component, OnInit, Output, EventEmitter, Input, HostListener, ElementRef } from '@angular/core';
import { CadastroPadraoComponent } from '../../shared/cadastro-padrao/cadastro-padrao.component';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../services/message.service';
import { StorageFunctions } from '../../general/storage';
import { UtilFunctions } from '../../general/utils-service';
import { ClientesService } from '../../services/clientes.service';
import { ItensService } from '../../services/itens.service';
import { UnidadesEmpresaService } from '../../services/unidades-empresa.service';
import { TipoItensService } from '../../services/tipo-itens.service';
import { Location } from '@angular/common';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
declare var $;

@Component({
  selector: 'app-valida-automacao',
  templateUrl: './valida-automacao.component.html',
  styleUrls: ['./valida-automacao.component.css']
})
export class ValidaAutomacaoComponent extends CadastroPadraoComponent implements OnInit {

  @Output() formFinished = new EventEmitter<object>();
  @Input() itens = [];
  @Input() clienteId = null;
  @Input() clienteContaOrdem = false;
  @Input() header = 'Validar Faturamento';
  @Input() clienteFinal: boolean;
  @Input() entregaFutura: boolean;
  @Input() tipoNFeID: number;
  @Input() vendaAmbulante: boolean;
  @Input() vendaAOrdem: boolean;
  @Input() saidaDeposito: boolean;
  private pagina = 0;
  itemsConsulta = [];
  tipoItens: any[];
  unidadeEmpresa: any;
  cliente: any;
  private clienteInit: any;
  private consultandoItem = 0;
  listaCfops = [];

  constructor(route: ActivatedRoute,
    message: MessageService,
    storage: StorageFunctions,
    location: Location,
    utilFunctions: UtilFunctions,
    private clientesService: ClientesService,
    private elementRef: ElementRef,
    private itensService: ItensService,
    private unidadesEmpresaService: UnidadesEmpresaService,
    private tiposItensService: TipoItensService) {
    super(route, utilFunctions, message, storage, location);
    this.cancelaVerificacaoPermissao = true;
  }

  private carregaTipoItens() {
    return new Promise((resolve, reject) => {
      this.tiposItensService.get()
        .pipe(finalize(() => resolve({})))
        .subscribe(result => {
          this.tipoItens = result;
        });
    });
  }

  private carregaCliente() {
    return new Promise((resolve, reject) => {
      this.clientesService.getClienteById(this.clienteId)
        .pipe(finalize(() => resolve({})))
        .subscribe(res => {
          this.clienteInit = Object.assign({}, res);
          this.cliente = Object.assign({}, res);
          this.cliente.clienteFinal = this.clienteFinal;
        });
    });
  }

  private carregaEmpresa() {
    return new Promise((resolve, reject) => {
      this.unidadesEmpresaService.getUnidadeEmpresa()
        .pipe(finalize(() => resolve({})))
        .subscribe(res => {
          this.unidadeEmpresa = res;
        });
    });
  }

  ngOnInit() {
    this.loading['dadosGerais'] = true;
    Promise.all([this.carregaTipoItens(), this.carregaCliente(), this.carregaEmpresa()])
      .then(() => {
        this.loading['dadosGerais'] = false;
        this.getInfoItens();
      });
  }

  @HostListener('scroll', ['$event'])
  scrollHandler(event) {
    if (event && event['target']) {
      const scrollTop = event['target'].scrollTop;
      const height = event['target'].clientHeight;
      const scrollHeight = event['target'].scrollHeight;

      if (scrollTop + height >= scrollHeight) {
        if (!this.loading['dadosItem'] && this.itemsConsulta.length < this.itens.length) {
          this.getInfoItens();
        }
      }
    }
  }

  getCRT(crt) {
    switch (crt) {
      case 1:
        return 'Simples Nacional';
      case 2:
        return 'Simples Nacional - excesso de sublimite de receita bruta';
      case 3:
        return 'Normal';
    }
  }

  getInfoItens() {
    this.loading['dadosItem'] = true;
    let consulta = this.itens.slice(this.pagina * 10, (this.pagina * 10) + 10);
    consulta = consulta.map(x => {
      return {
        ItemID: x.produto,
        CodigoFiscalID: x.codigoFiscalID || null,
        bonificacaoOuBrinde: x.bonificacaoOuBrinde,
      };
    });

    const param = {
      Cliente: this.cliente,
      TipoNFeID: this.tipoNFeID,
      Produtos: consulta,
      PorContaOrdem: this.clienteContaOrdem,
      EntregaFutura: this.entregaFutura,
      VendaAOrdem: this.vendaAOrdem,
      SaidaDeposito: this.saidaDeposito,
    };

    this.itensService.getPaginadoPorPedido(param)
      .pipe(finalize(() => this.loading['dadosItem'] = false))
      .subscribe(res => {

        if (res.length > 0) {
          this.pagina++;
        }

        res.forEach(element => {
          const newItem = {
            produto: Object.assign({}, element.produto),
            automacao: element.automacao,
            produtoInit: Object.assign({}, element.produto),
            bonificacaoOuBrinde: element.bonificacaoOuBrinde,
          };

          this.itemsConsulta.push(newItem);
        });

        if (this.itemsConsulta.length <= 10) {
          setTimeout(() => {
            this.elementRef.nativeElement.querySelector('#divTabe').scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 10);
        }
        this.atualizarListaCfops();
      });
  }

  getTypeItem(typeItemID) {
    let response = '';

    if (typeItemID) {
      response = this.tipoItens.find(element => element.id === typeItemID).descricao;
    }

    return response;
  }

  getValueRedIcms(value) {
    if (value > 0) { return 'Sim'; }
    return 'Não';
  }

  verificaTabelaAutomacao(event, indexItem, type = null) {

    if (type) {
      if (event.target.checked) {
        if (type === 'sujeitoST') {
          this.itemsConsulta[indexItem].produto.isentoIcms = false;
        } else if (type === 'isencaoICMS') {
          this.itemsConsulta[indexItem].produto.substituicaoTributaria = false;
        }
      }
    }

    let reducaoBaseIcms = false;

    if (this.itemsConsulta[indexItem].produto.aliquotaicms
      && this.itemsConsulta[indexItem].produto.aliquotaicms.percentualRedutorICMS > 0) {
      reducaoBaseIcms = true;
    }

    const consulta = {
      Cliente: this.cliente,
      ItemID: this.itemsConsulta[indexItem].produto.id,
      TipoItemId: this.itemsConsulta[indexItem].produto.tipoItemid,
      SubstituicaoTributaria: this.itemsConsulta[indexItem].produto.substituicaoTributaria,
      IsentoIcms: this.itemsConsulta[indexItem].produto.isentoIcms,
      reducaoBaseIcms: reducaoBaseIcms,
      PorContaOrdem: this.clienteContaOrdem,
      EntregaFutura: this.entregaFutura,
      bonificacaoOuBrinde: this.itemsConsulta[indexItem].bonificacaoOuBrinde,
      vendaAmbulante: this.vendaAmbulante,
      vendaAOrdem: this.vendaAOrdem,
    };

    this.itemsConsulta[indexItem].consultando = true;
    this.loading['dadosItem'] = true;
    this.consultandoItem++;
    this.itensService.verificaTabelaAutomacao(consulta)
      .pipe(finalize(() => {
        this.itemsConsulta[indexItem].consultando = false;
        this.loading['dadosItem'] = false;
        this.consultandoItem--;
      }))
      .subscribe(res => {
        this.itemsConsulta[indexItem].automacao = res;
        this.atualizarListaCfops();
      });
  }

  verificaAutomacao(indexItem) {
    if (!this.itemsConsulta[indexItem].automacao) { return 'red'; }
    return '';
  }

  verificaDentroEstado() {
    if (this.cliente.cidade.estado.id === this.unidadeEmpresa.cidade.estado.id) {
      return true;
    }
    return false;
  }

  alteraDadoCliente() {
    this.itemsConsulta.forEach(async (element, index) => {
      let reducaoBaseIcms = false;

      if (this.itemsConsulta[index].produto.aliquotaicms
        && this.itemsConsulta[index].produto.aliquotaicms.percentualRedutorICMS > 0) {
        reducaoBaseIcms = true;
      }

      const consulta = {
        Cliente: this.cliente,
        ItemID: this.itemsConsulta[index].produto.id,
        TipoItemId: this.itemsConsulta[index].produto.tipoItemid,
        SubstituicaoTributaria: this.itemsConsulta[index].produto.substituicaoTributaria,
        IsentoIcms: this.itemsConsulta[index].produto.isentoIcms,
        ReducaoBaseIcms: reducaoBaseIcms,
        PorContaOrdem: this.clienteContaOrdem,
        EntregaFutura: this.entregaFutura,
        VendaAOrdem: this.vendaAOrdem,
      };

      this.itemsConsulta[index].consultando = true;
      this.loading['dadosItem'] = true;
      this.consultandoItem++;
      this.itensService.verificaTabelaAutomacao(consulta)
        .pipe(finalize(() => {
          this.itemsConsulta[index].consultando = false;
          this.loading['dadosItem'] = false;
          this.consultandoItem--;
        }))
        .subscribe(res => {
          this.itemsConsulta[index].automacao = res;
          this.atualizarListaCfops();
        });
    });
  }

  atualizarListaCfops() {
    this.listaCfops = [];
    this.itemsConsulta.forEach((element) => {
      if (element.automacao !== null) {
        this.listaCfops.push(element.automacao.codigoCFOP);
      }
    })
  }

  salvar(emitirNfe = false) {
    if (!this.verificaBloqueio()) {

      const listPromisses = [];
      this.loading['salvando'] = true;

      if (this.clienteInit.simples !== this.cliente.simples
        || this.clienteInit.clienteFinal !== this.cliente.clienteFinal) {

        const clientePromisse = new Promise((resolve, reject) => {
          this.clientesService.updateCliente(this.cliente)
            .pipe(finalize(() => resolve({})))
            .subscribe(() => {
              // Cliente Atualizado
            }, () => {
              reject('Erro ao salvar alterações no cliente!');
            });
        });

        listPromisses.push(clientePromisse);
      }

      this.itemsConsulta.forEach((element, index) => {
        if (element.produtoInit.tipoItemid !== element.produto.tipoItemid
          || element.produtoInit.substituicaoTributaria !== element.produto.substituicaoTributaria
          || element.produtoInit.isentoIcms !== element.produto.isentoIcms) {
          const itemPromisse = new Promise((resolve, reject) => {
            this.itensService.update(element.produto)
              .pipe(finalize(() => resolve({})))
              .subscribe(() => {
                // Item Atualizado
              }, () => {
                reject('Erro ao salvar alterações no item: ' + element.produto.nome);
              });
          });

          listPromisses.push(itemPromisse);
        }

        if (this.itens[index]) {
          this.itens[index].bonificacaoOuBrinde = element.bonificacaoOuBrinde;
        }
      });

      Promise.all(listPromisses).then(() => {
        const clienteFinal = this.cliente.clienteFinal;
        this.loading['salvando'] = false;

        if (!emitirNfe) {
          swal('Sucesso', 'Alterações salvas com sucesso!', 'success');
        }

        this.formFinished.emit({ emitirNfe, clienteFinal });

      }).catch(err => {
        swal('Erro', err, 'error');
      });

    } else {
      swal('Erro', 'Espere todas as consultas acabarem!', 'error');
    }
  }

  fechar() {
    this.formFinished.emit();
  }

  verificaBloqueio() {
    if (this.consultandoItem > 0 || this.loading['dadosItem'] || this.loading['salvando']) {
      return true;
    }

    return false;
  }

  getTipoPedido() {
    if (this.entregaFutura) {
      return 'Entrega Futura';
    }
    if (this.clienteContaOrdem) {
      return 'Conta e Ordem';
    }
    return 'Venda';
  }
}
