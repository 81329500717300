import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { ClientesService } from '../../../services/clientes.service';
import { ValidacaoNumeroWhatsAppService } from '../../../services/validacao-numero-whatsApp.service';

@Component({
  selector: 'app-whatsapp-otp',
  templateUrl: './whatsapp-otp.component.html',
  styleUrls: ['./whatsapp-otp.component.css'],
})
export class WhatsappOtpComponent extends ModalCadastroComponent implements OnInit, AfterViewInit {
  @ViewChild('modal', { static: true }) modal;
  form: FormGroup;
  errorMessage = '';
  timerRunning = false;
  remainingTime = 60;

  constructor(
    private validacaoNumeroWhatsAppSerivce: ValidacaoNumeroWhatsAppService,
    private clientesService: ClientesService,
  ) {
    super();
  }

  get isFireFox() {
    return navigator.userAgent.indexOf("Firefox") > -1;
  }

  get textoReceberCodigo() {
    return !this.timerRunning ? "Clique aqui para receber um novo código"
      : `Você pode solicitar um novo código em ${this.remainingTime} segundos`;
  }

  ngOnInit() {
    this.form = new FormGroup({
      otp: new FormControl(null, [Validators.required]),
      otp1: new FormControl(null, [Validators.required]),
      otp2: new FormControl(null, [Validators.required]),
      otp3: new FormControl(null, [Validators.required]),
      otp4: new FormControl(null, [Validators.required]),
      otp5: new FormControl(null, [Validators.required]),
    });

    this.enviarCodigo();
  }

  ngAfterViewInit(): void {
    // autofocus não está focando 100% das vezes.
    document.getElementById('otp0').focus();
  }

  onInput(event: Event, index: number) {
    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue !== null && inputValue !== '' && index < 5) {
      document.getElementById(`otp${index + 1}`).focus();
      return;
    }

    if (index === 5 && this.form.valid) {
      this.verificarCodigo();
    }
  }

  onBackSpace(index: number) {
    document.getElementById(`otp${index - 1}`).focus();
  }

  async verificarCodigo() {
    if (this.form.invalid) {
      this.errorMessage = 'Informe todos os números do código.';
      document.getElementById('otp').focus();
      return;
    }

    try {
      this.errorMessage = '';
      this.loading['verificando'] = true;
      const otp = this.form.get('otp').value;
      const otp1 = this.form.get('otp1').value;
      const otp2 = this.form.get('otp2').value;
      const otp3 = this.form.get('otp3').value;
      const otp4 = this.form.get('otp4').value;
      const otp5 = this.form.get('otp5').value;
      const codigo = otp + otp1 + otp2 + otp3 + otp4 + otp5;

      await this.validacaoNumeroWhatsAppSerivce.validarOtp(codigo, this.prop).toPromise();
      this.finalizar();
    } catch (error) {
      console.error(error);
      this.errorMessage = error.msg.errors[0];
    } finally {
      this.loading['verificando'] = false;
    }
  }

  enviarCodigo() {
    if (this.loading['verificando'] || this.timerRunning) {
      return;
    }

    this.timerRunning = true;
    this.updateTimer();

    this.clientesService.enviarCodigoWhatsApp(this.prop).toPromise();
  }

  updateTimer() {
    if (this.remainingTime > 0) {
      setTimeout(() => {
        this.remainingTime--;
        this.updateTimer();
      }, 1000);
    } else {
      this.timerRunning = false;
      this.remainingTime = 60;
    }
  }

  async voltar() {
    const res = await swal({
      title: 'Deseja mesmo voltar?',
      text: 'Essa é uma ação necessária, porém poderá ser realizada em outro momento.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Não',
      cancelButtonText: 'Sim',
      allowOutsideClick: false,
    });

    if (res.dismiss) {
      this.onClose();
    }
  }

  finalizar() {
    swal('Verificação Bem-Sucedida!', 'Número de telefone confirmado com êxito.', 'success');
    this.onClose(true);
  }

  async colar(e = null) {
    try {
      let codigo = e
        ? (e['clipboardData'] || window['clipboardData']).getData('text')
        //@ts-ignore
        : await navigator.clipboard.readText();

      codigo = codigo.replace(/(\r\n|\n|\r)/gm, ' ').replace(/[^0-9]/g, '');

      this.form.get('otp').setValue(codigo[0]);
      this.form.get('otp1').setValue(codigo[1]);
      this.form.get('otp2').setValue(codigo[2]);
      this.form.get('otp3').setValue(codigo[3]);
      this.form.get('otp4').setValue(codigo[4]);
      this.form.get('otp5').setValue(codigo[5]);

      if (!codigo || codigo.length < 6) {
        return;
      }

      setTimeout(() => {
        document.getElementById('otp5').focus();
        this.verificarCodigo();
      });
    } catch (error) {
      // Para esconder o erro do FireFox
    }
  }

  show() {
    this.modal.show();
  }

  onClose(success = false) {
    this.closed.emit(success);
  }
}
