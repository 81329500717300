<ng-select #select appendTo="body" [disabled]="disabled || userRepresentante || loading['vendedores']"
  [ngModel]="vendedorSelecionado" [loading]="loading['vendedores']" (change)="vendedorChanged($event)"
  placeholder="Selecione" [items]="vendedores" bindLabel="nome" bindValue="id">

  <ng-template ng-label-tmp let-item="item">
    {{nomeRepresentante(item)}}
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    <div style="display: flex; align-items: center">
      <span class="ng-option-label" style="overflow: hidden; width: 100%; text-overflow: ellipsis">{{nomeRepresentante(item)}}</span>
      <i (click)="subscribeModal(); abrirModal.editar('representante', item.id); $event.stopPropagation(); select.close();"
        style="float: right; font-size: 17px; color: #3F88C5" class="fa fa-edit"></i>
    </div>
  </ng-template>

  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option">
      <div style="display: flex; align-items: center">
        <span style="overflow: hidden; width: 100%; text-overflow: ellipsis">{{searchTerm}}</span>
        <i (click)="subscribeModal(); abrirModal.cadastrar('representante', searchTerm); $event.stopPropagation(); select.close();"
          style="float: right; cursor: pointer; font-size: 17px; color: #3F88C5" class="fa fa-plus"></i>
      </div>
    </div>
  </ng-template>
</ng-select>

<app-abrir-modal #abrirModal></app-abrir-modal>
