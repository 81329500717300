import { Component, ComponentFactoryResolver, ViewContainerRef, ViewChild, Output, EventEmitter, Renderer2 } from '@angular/core';
import { CertificadoDigitalComponent } from '../../modais/cadastros/certificado-digital/certificado-digital.component';
import { FornecedorComponent } from '../../modais/cadastros/fornecedor/fornecedor.component';
import { AlmoxarifadoComponent } from '../../modais/cadastros/almoxarifado/almoxarifado.component';
import { LinhaProdutoComponent } from '../../modais/cadastros/linha-produto/linha-produto.component';
import { PortadorComponent } from '../../modais/cadastros/portador/portador.component';
import { PedidoComponent } from '../../modais/consultas/pedido/pedido.component';
import { CentroCustoComponent } from '../../modais/cadastros/centro-custo/centro-custo.component';
import { ContaCorrenteComponent } from '../../modais/cadastros/conta-corrente/conta-corrente.component';
import { GrupoDespesaComponent } from '../../modais/cadastros/grupo-despesa/grupo-despesa.component';
import { TipoDespesaComponent } from '../../modais/cadastros/tipo-despesa/tipo-despesa.component';
import { AcessosUsuarioComponent } from '../../modais/consultas/acessos-usuario/acessos-usuario.component';
import { EstornoContasReceberComponent } from '../../modais/cadastros/estorno-contas-receber/estorno-contas-receber.component';
import { ReplicaParcelasCobrancaComponent } from '../../modais/cadastros/replica-parcelas-cobranca/replica-parcelas-cobranca.component';
import { RepresentanteComponent } from '../../modais/cadastros/representante/representante.component';
import { ClienteComponent } from '../../modais/cadastros/cliente/cliente.component';
import { ReplicaParcelasFornecedorComponent } from '../../modais/cadastros/replica-parcelas-fornecedor/replica-parcelas-fornecedor.component';
import { ConfiguracaoNfceComponent } from '../../modais/cadastros/configuracao-nfce/configuracao-nfce.component';
import { MvaPorEstadosComponent } from '../../modais/cadastros/mva-por-estados/mva-por-estados.component';
import { CadastroFiscalItemNfceComponent } from '../../modais/cadastros/cadastro-fiscal-item-nfce/cadastro-fiscal-item-nfce.component';
import { CondicaoPagamentoComponent } from '../../modais/cadastros/condicao-pagamento/condicao-pagamento.component';
import { ExibirVideoComponent } from '../../modais/diversos/exibir-video/exibir-video.component';
import { TipoComissaoComponent } from '../../modais/cadastros/tipo-comissao/tipo-comissao.component';
import { TabelaPrecoDetalheComponent } from '../../venda/cadastros/tabelas-preco/tabela-preco-detalhe/tabela-preco-detalhe.component';
import { EditarUsuarioComponent } from '../../modais/cadastros/editar-usuario/editar-usuario.component';
import { AliquotaIcmsComponent } from '../../modais/cadastros/aliquota-icms/aliquota-icms.component';
import { CadastroClienteEnderecoComponent } from '../../modais/cadastros/cadastro-cliente-endereco/cadastro-cliente-endereco.component';
import { MotivoMovimentacaoEstoqueComponent } from '../../modais/cadastros/motivo-movimentacao-estoque/motivo-movimentacao-estoque.component';
import { PesagemVolumesComponent } from '../../modais/cadastros/pesagem-volumes/pesagem-volumes.component';
import { CadastroClassificacaoFiscalComponent } from '../../modais/cadastros/cadastro-classificacao-fiscal/cadastro-classificacao-fiscal.component';
import { UsuariosEmpresaComponent } from '../../modais/consultas/usuarios-empresa/usuarios-empresa.component';
import { NCMCloudComponent } from '../../modais/consultas/ncm-cloud/ncm-cloud.component';
import { SelecionarTituloOfxComponent } from '../../modais/consultas/selecionar-titulo-ofx/selecionar-titulo-ofx.component';
import { RelatorioEstoqueComponent } from '../../modais/relatorios/estoque/relatorio-estoque/relatorio-estoque.component';
import { RelatorioHistoricoEstoqueComponent } from '../../modais/relatorios/estoque/relatorio-historico-estoque/relatorio-historico-estoque.component';
import { ImportarNfeComponent } from '../../modais/cadastros/importar-nfe/importar-nfe.component';
import { AtendimentosComponent } from '../../modais/relatorios/atendimentos/atendimentos.component';
import { RelacaoDeClientesComponent } from '../../modais/relatorios/clientes/relacao-de-clientes/relacao-de-clientes.component';
import { ComissoesComponent } from '../../modais/relatorios/comissoes/comissoes/comissoes.component';
import { ConclusaoAdesaoComponent } from '../../modais/relatorios/conclusao-adesao/conclusao-adesao.component';
import { DestinacaoRecursosComponent } from '../../modais/relatorios/destinacao-recursos/destinacao-recursos.component';
import { ModalRelatorioRetencoesComponent } from '../../modais/relatorios/notas-fiscais/modal-relatorio-retencoes/modal-relatorio-retencoes.component';
import { NotasFiscaisComunicacaoComponent } from '../../modais/relatorios/notas-fiscais/notas-fiscais-comunicacao/notas-fiscais-comunicacao.component';
import { RastreabilidadeLotesMedicamentoComponent } from '../../modais/relatorios/notas-fiscais/rastreabilidade-lotes-medicamento/rastreabilidade-lotes-medicamento.component';
import { ResumoVendasPorNcmComponent } from '../../modais/relatorios/notas-fiscais/resumo-vendas-por-ncm/resumo-vendas-por-ncm.component';
import { SaidasPorEstadoComponent } from '../../modais/relatorios/notas-fiscais/saidas-por-estado/saidas-por-estado.component';
import { VendaProdutosNfceComponent } from '../../modais/relatorios/notas-fiscais/venda-produtos-nfce/venda-produtos-nfce.component';
import { RelacaoVendasProdutoComponent } from '../../modais/relatorios/pedidos/relacao-vendas-produto/relacao-vendas-produto.component';
import { SaldoContasComponent } from '../../modais/relatorios/saldo-contas/saldo-contas/saldo-contas.component';
import { EnviarEmailComponent } from '../../modais/diversos/enviar-email/enviar-email.component';
import { ExportarNFEComponent } from '../../modais/consultas/gerenciamento/exportar-nfe/exportar-nfe.component';
import { ExportarNfceComponent } from '../../modais/consultas/gerenciamento/exportar-nfce/exportar-nfce.component';
import { VincaularTipoDespesaEventosBancariosComponent } from '../../modais/cadastros/vincaular-tipo-despesa-eventos-bancarios/vincaular-tipo-despesa-eventos-bancarios.component';
import { LancarEstoqueComponent } from '../../modais/cadastros/lancar-estoque/lancar-estoque/lancar-estoque.component';
import { RelatorioGerencialComponent } from '../../modais/relatorios/notas-fiscais/relatorio-gerencial/relatorio-gerencial.component';
import { RelatorioProdutosComponent } from '../../modais/relatorios/produtos/relatorio-produtos/relatorio-produtos.component';
import { RelatorioContasPagarComponent } from '../../modais/relatorios/relatorio-contas-pagar/relatorio-contas-pagar/relatorio-contas-pagar.component';
import { GraficosComponent } from '../../modais/relatorios/dashboard/graficos/graficos.component';
import { AvisoCertificadoComponent } from '../../modais/diversos/aviso-certificado/aviso-certificado.component';
import { ReplicaHorariosAgendaComponent } from '../../modais/cadastros/replica-horarios-agenda/replica-horarios-agenda.component';
import { DetalhesNfeComponent } from '../../modais/consultas/detalhes-nfe/detalhes-nfe.component';
import { ItensManifestoComponent } from '../../modais/consultas/itens-manifesto/itens-manifesto.component';
import { BuscaItensRetornoComponent } from '../../modais/consultas/busca-itens-retorno/busca-itens-retorno.component';
import { SerieAlternativaComponent } from '../../modais/cadastros/serie-alternativa/serie-alternativa.component';
import { NotasFiscaisComponent } from '../../modais/consultas/notas-fiscais/notas-fiscais.component';
import { ListaItensFechamentoComponent } from '../../modais/diversos/lista-itens-faturacao/lista-itens-fechamento.component';
import { LoteLancamentosContabeisComponent } from '../../modais/cadastros/lote-lancamentos-contabeis/lote-lancamentos-contabeis.component';
import { PedidosEntregaComponent } from '../../modais/relatorios/pedidos-entrega/pedidos-entrega.component';
import { IntermediadoresComponent } from '../../modais/cadastros/intermediadores/intermediadores.component';
import { EmissoesPorCfopComponent } from '../../modais/relatorios/notas-fiscais/emissoes-por-cfop/emissoes-por-cfop.component';
import { ProdutosCloneComponent } from '../../modais/cadastros/produtos-clone/produtos-clone.component';
import { RelatorioProdutosPorOrigemComponent } from '../../modais/relatorios/produtos/relatorio-produtos-por-origem/relatorio-produtos-por-origem.component';
import { MapaRemessasRetornosComponent } from '../../modais/relatorios/notas-fiscais/mapa-remessas-retornos/mapa-remessas-retornos.component';
import { EvasaoComponent } from '../../modais/relatorios/evasao/evasao.component';
import { EditarCfopNotaEntradaComponent } from '../../modais/cadastros/editar-cfop-nota-entrada/editar-cfop-nota-entrada.component';
import { ListarNotasCorrespondentesComponent } from '../../modais/consultas/listar-notas-correspondentes/listar-notas-correspondentes.component';
import { CartaCorrecaoComponent } from '../../modais/consultas/carta-correcao/carta-correcao.component';
import { ComissoesSaldoComponent } from '../../modais/relatorios/comissoes/comissoes-saldo/comissoes-saldo.component';
import { PoliticaDePrivacidadeComponent } from '../../modais/diversos/politica-de-privacidade/politica-de-privacidade.component';
import { AlterarDadosFiscaisComponent } from '../../modais/cadastros/alterar-dados-fiscais/alterar-dados-fiscais.component';
import { NotasFiscaisVendaPorClienteComponent } from '../../modais/relatorios/notas-fiscais-venda-por-cliente/notas-fiscais-venda-por-cliente.component';
import { NotasFiscaisVendaPorProdutoComponent } from '../../modais/relatorios/notas-fiscais-venda-por-produto/notas-fiscais-venda-por-produto.component';
import { BuscaItensRemessaComponent } from '../../modais/consultas/busca-itens-remessa/busca-itens-remessa.component';
import { FormaPagamentoModalComponent } from '../../gerenciamento/portal-do-cliente/forma-pagamento-modal/forma-pagamento-modal.component';
import { AvisoFaturasComponent } from '../../modais/diversos/aviso-faturas/aviso-faturas.component';
import { BuscaItensRomaneioComponent } from '../../modais/consultas/busca-itens-romaneio/busca-itens-romaneio.component';
import { BuscarRomaneioComponent } from '../../modais/consultas/buscar-romaneio/buscar-romaneio.component';
import { RelatorioRomaneioCargaComponent } from '../../modais/relatorios/romaneio-carga/relatorio-romaneio-carga/relatorio-romaneio-carga.component';
import { BuscaItensPorContaOrdemComponent } from '../../modais/consultas/busca-itens-por-conta-ordem/busca-itens-por-conta-ordem.component';
import { FaturamentoDetalhadoComponent } from '../../modais/consultas/faturamento-detalhado/faturamento-detalhado.component';
import { SelecionarCfopItensReplicadosComponent } from '../../modais/cadastros/selecionar-cfop-itens-replicados/selecionar-cfop-itens-replicados.component';
import { WhatsappOtpComponent } from '../../modais/cadastros/whatsapp-otp/whatsapp-otp.component';
import { SelecionarUsuarioAlterarSenhaComponent } from '../../modais/diversos/selecionar-usuario-alterar-senha/selecionar-usuario-alterar-senha.component';
import { CadastroServicoComponent } from '../../modais/cadastros/cadastro-servico/cadastro-servico.component';
import { ModalCadastroEnderecoRetiradaComponent } from '../../modais/cadastros/modal-cadastro-endereco-retirada/modal-cadastro-endereco-retirada.component';
import { ExportarNFSEComponent } from '../../modais/consultas/gerenciamento/exportar-nfse/exportar-nfse.component';
import { ContratarIntermediadorRecebimento } from '../../modais/diversos/contratar-intermediador-recebimento/contratar-intermediador-recebimento.component';
import { ModalSegmentosVinculadosComponent } from '../../modais/diversos/modal-segmentos-vinculados/modal-segmentos-vinculados.component';
import { ModalProcessoImportacaoComponent } from '../../modais/cadastros/modal-processo-importacao/modal-processo-importacao.component';
import { VisualizarProcessosDeImportacaoComponent } from '../../modais/consultas/visualizar-processos-de-importacao/visualizar-processos-de-importacao.component';
import { ModalUnidadesConsumoVinculadosComponent } from '../../modais/diversos/modal-unidades-consumo-vinculados/modal-unidades-consumo-vinculados.component';
import { ModalCadastroUnidadesConsumoComponent } from '../../modais/cadastros/modal-cadastro-unidades-consumo/modal-cadastro-unidades-consumo.component';
import { BuscaItensNotaEntradaComponent } from '../../modais/consultas/busca-itens-nota-entrada/busca-itens-nota-entrada.component';
import { AtalhosClientesComponent } from '../../modais/cadastros/atalhos-clientes/atalhos-clientes.component';
import { CteImportarNfePropriaComponent } from '../../modais/diversos/cte-importar-nfe-propria/cte-importar-nfe-propria.component';
import { CteImportarXmlCteComponent } from '../../modais/diversos/cte-importar-xml-cte/cte-importar-xml-cte.component';
import { CteImportarXmlNfeComponent } from '../../modais/diversos/cte-importar-xml-nfe/cte-importar-xml-nfe.component';
import { ExportarCTEComponent } from '../../modais/consultas/gerenciamento/exportar-cte/exportar-cte.component';
import { EnviarCartaCorrecaoComponent } from '../../modais/diversos/enviar-carta-correcao/enviar-carta-correcao.component';
import { CartaCorrecaoCteComponent } from '../../modais/consultas/carta-correcao-cte/carta-correcao-cte.component';
import { EditarCfopNotaSaidaComponent } from '../../modais/cadastros/editar-cfop-nota-saida/editar-cfop-nota-saida.component';
import { VendaNfceComponent } from '../../modais/relatorios/notas-fiscais/venda-nfce/venda-nfce.component';
import { MdfeImportarNfePropriaComponent } from '../../modais/diversos/mdfe-importar-nfe-propria/mdfe-importar-nfe-propria.component';
import { MdfeImportarCteProprioComponent } from '../../modais/diversos/mdfe-importar-cte-proprio/mdfe-importar-cte-proprio.component';
import { MdfeEncerramentoComponent } from '../../modais/diversos/mdfe-encerramento/mdfe-encerramento.component';
import { MdfeImportarXmlNfeComponent } from '../../modais/diversos/mdfe-importar-xml-nfe/mdfe-importar-xml-nfe.component';
import { MdfeImportarXmlCteComponent } from '../../modais/diversos/mdfe-importar-xml-cte/mdfe-importar-xml-cte.component';
import { ExportarMDFEComponent } from '../../modais/consultas/gerenciamento/exportar-mdfe/exportar-mdfe.component';
import { VeiculoComponent } from '../../modais/cadastros/veiculo/veiculo.component';
import { CadastroVendedorUsuarioComponent } from '../../modais/cadastros/cadastro-vendedor-usuario/cadastro-vendedor-usuario.component';
import { MotoristaComponent } from '../../modais/cadastros/motorista/motorista.component';
import { RetornoImportarNfeComponent } from '../../modais/consultas/retorno-importar-nfe/retorno-importar-nfe.component';

@Component({
  selector: 'app-abrir-modal',
  templateUrl: './abrir-modal.component.html',
  styleUrls: ['./abrir-modal.component.css']
})
export class AbrirModalComponent {

  componentRegister = {
    'certificadoDigital': CertificadoDigitalComponent,
    'fornecedor': FornecedorComponent,
    'almoxarifado': AlmoxarifadoComponent,
    'linhaProduto': LinhaProdutoComponent,
    'portador': PortadorComponent,
    'pedido': PedidoComponent,
    'centroCusto': CentroCustoComponent,
    'contaCorrente': ContaCorrenteComponent,
    'grupoDespesa': GrupoDespesaComponent,
    'lancamentosContabeis': LoteLancamentosContabeisComponent,
    'tipoDespesa': TipoDespesaComponent,
    'estornoContaReceber': EstornoContasReceberComponent,
    'acessosUsuario': AcessosUsuarioComponent,
    'replicaParcelamentoCobranca': ReplicaParcelasCobrancaComponent,
    'representante': RepresentanteComponent,
    'cliente': ClienteComponent,
    'replicaParcelamentoFornecedor': ReplicaParcelasFornecedorComponent,
    'configuracaoNfce': ConfiguracaoNfceComponent,
    'mvaPorEstado': MvaPorEstadosComponent,
    'cadastroFiscalItemNfce': CadastroFiscalItemNfceComponent,
    'condicaoPagamento': CondicaoPagamentoComponent,
    'intermediadorCadastro': IntermediadoresComponent,
    'tipoComissao': TipoComissaoComponent,
    'tabelasPreco': TabelaPrecoDetalheComponent,
    'exibirVideo': ExibirVideoComponent,
    'aliquotaIcms': AliquotaIcmsComponent,
    'editarUsuarioClientes': EditarUsuarioComponent,
    'cadastroClienteEndereco': CadastroClienteEnderecoComponent,
    'motivoMovimentacaoEstoque': MotivoMovimentacaoEstoqueComponent,
    'pesagemVolumes': PesagemVolumesComponent,
    'cadastroClassificacaoFiscal': CadastroClassificacaoFiscalComponent,
    'usuariosEmpresa': UsuariosEmpresaComponent,
    'ncmCloud': NCMCloudComponent,
    'selecionarTituloOfx': SelecionarTituloOfxComponent,
    'relatorioEstoque': RelatorioEstoqueComponent,
    'historicoEstoque': RelatorioHistoricoEstoqueComponent,
    'importarNfe': ImportarNfeComponent,
    'atendimentos': AtendimentosComponent,
    'relacaoDeClientes': RelacaoDeClientesComponent,
    'comissao': ComissoesComponent,
    'conclusaoAdesao': ConclusaoAdesaoComponent,
    'destinacaoRecursos': DestinacaoRecursosComponent,
    'modalRelatorioRetencoes': ModalRelatorioRetencoesComponent,
    'notasFiscaisComunicacao': NotasFiscaisComunicacaoComponent,
    'rastreabilidade': RastreabilidadeLotesMedicamentoComponent,
    'vendasPorNCM': ResumoVendasPorNcmComponent,
    'saidasPorEstado': SaidasPorEstadoComponent,
    'vendaProdutosNfce': VendaProdutosNfceComponent,
    'relacaoVendasPorProdutoPedido': RelacaoVendasProdutoComponent,
    'saldoContas': SaldoContasComponent,
    'exportacaoNfe': ExportarNFEComponent,
    'exportacaoNfce': ExportarNfceComponent,
    'enviarEmail': EnviarEmailComponent,
    'enviarCartaCorrecao': EnviarCartaCorrecaoComponent,
    'vincularTipoDespesaEventosBancarios': VincaularTipoDespesaEventosBancariosComponent,
    'lancarEstoque': LancarEstoqueComponent,
    'gerencial': RelatorioGerencialComponent,
    'relatorioProdutos': RelatorioProdutosComponent,
    'relatorioContasPagar': RelatorioContasPagarComponent,
    'graficos': GraficosComponent,
    'aviso-certificado': AvisoCertificadoComponent,
    'replicaHorariosAgenda': ReplicaHorariosAgendaComponent,
    'detalhesNfe': DetalhesNfeComponent,
    'buscaItensRetorno': BuscaItensRetornoComponent,
    'itensManifesto': ItensManifestoComponent,
    'serie-alternativa': SerieAlternativaComponent,
    'notas-fiscais': NotasFiscaisComponent,
    'lista-itens-fechamento': ListaItensFechamentoComponent,
    'pedidos-entrega': PedidosEntregaComponent,
    'emissoesPorCfop': EmissoesPorCfopComponent,
    'produtos-clone': ProdutosCloneComponent,
    'relatorioProdutosPorOrigem': RelatorioProdutosPorOrigemComponent,
    'mapaRemessasRetornos': MapaRemessasRetornosComponent,
    'relatorioEvasao': EvasaoComponent,
    'editarCfopNotaEntrada': EditarCfopNotaEntradaComponent,
    'editarCfopNotaSaida': EditarCfopNotaSaidaComponent,
    'listarNotasCorrespondentes': ListarNotasCorrespondentesComponent,
    'carta-correcao': CartaCorrecaoComponent,
    'comissaoSaldo': ComissoesSaldoComponent,
    'politicaDePrivacidade': PoliticaDePrivacidadeComponent,
    'alterarDadosFiscais': AlterarDadosFiscaisComponent,
    'nfePorCliente': NotasFiscaisVendaPorClienteComponent,
    'vendasPorProduto': NotasFiscaisVendaPorProdutoComponent,
    'buscaItensRemessa': BuscaItensRemessaComponent,
    'buscaItensNotaEntrada': BuscaItensNotaEntradaComponent,
    'retornoImportarNfe': RetornoImportarNfeComponent,
    'formaPagamento': FormaPagamentoModalComponent,
    'avisoFaturas': AvisoFaturasComponent,
    'buscaItensRomaneio': BuscaItensRomaneioComponent,
    'buscarRomaneio': BuscarRomaneioComponent,
    'imprimirRomaneio': RelatorioRomaneioCargaComponent,
    'buscaItensPorContaOrdem': BuscaItensPorContaOrdemComponent,
    'faturamentoDetalhado': FaturamentoDetalhadoComponent,
    'selecionarCFOPReplicaItens': SelecionarCfopItensReplicadosComponent,
    'whatsappCode': WhatsappOtpComponent,
    'selecionarUsuario': SelecionarUsuarioAlterarSenhaComponent,
    'cadastroServico': CadastroServicoComponent,
    'modalEnderecoRetirada': ModalCadastroEnderecoRetiradaComponent,
    'exportacaoNfse': ExportarNFSEComponent,
    'contratarIntermediadorRecebimento': ContratarIntermediadorRecebimento,
    'segmentoEmpresas': ModalSegmentosVinculadosComponent,
    'processoImportacao': ModalProcessoImportacaoComponent,
    'visualizarProcessosDeImportacao': VisualizarProcessosDeImportacaoComponent,
    'unidadeConsumoPedido': ModalUnidadesConsumoVinculadosComponent,
    'unidadesConsumo': ModalCadastroUnidadesConsumoComponent,
    'editarAtalhos': AtalhosClientesComponent,
    'cteImportarNfePropria': CteImportarNfePropriaComponent,
    'cteImportarXmlNfe': CteImportarXmlNfeComponent,
    'cteImportarXmlCte': CteImportarXmlCteComponent,
    'exportacaoCte': ExportarCTEComponent,
    'carta-correcao-cte': CartaCorrecaoCteComponent,
    'vendaNfce': VendaNfceComponent,
    'mdfeImportarNfePropria': MdfeImportarNfePropriaComponent,
    'mdfeImportarCteProprio': MdfeImportarCteProprioComponent,
    'mdfeEncerramento': MdfeEncerramentoComponent,
    'mdfeImportarXmlNfe': MdfeImportarXmlNfeComponent,
    'mdfeImportarXmlCte': MdfeImportarXmlCteComponent,
    'exportacaoMdfe': ExportarMDFEComponent,
    'veiculo': VeiculoComponent,
    'cadastroVendedorUsuario': CadastroVendedorUsuarioComponent,
    'motorista': MotoristaComponent,
  };

  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver, private renderer: Renderer2) { }

  @Output() closed: EventEmitter<any> = new EventEmitter();

  componentRef: any;

  private setComponent(modal) {
    this.container.clear();
    const factory = this.resolver.resolveComponentFactory(this.componentRegister[modal]);
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.closed.subscribe(this.modalClosed.bind(this));
  }

  abrir(modal, someProp = null) {
    this.setComponent(modal);
    this.componentRef.instance.prop = someProp;
    this.componentRef.instance.show(someProp);
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  editar(modal, id) {
    this.setComponent(modal);
    this.componentRef.instance.editar(id);
  }

  cadastrar(modal, searchTerm) {
    this.setComponent(modal);
    this.componentRef.instance.cadastrar(searchTerm);
  }

  modalClosed(value) {
    setTimeout(() => { this.componentRef.destroy(); this.closed.emit(value); }, 300);
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }
}
