<app-temp-modal-cadastro #modalConsultar textHeader="Importação Notas Fiscais Remessa" [customClass]="'modal-lg'" (closed)="closed.emit()"
  [modalContentId]="'modal-retorno-importar-nfe'">
  <div class="app-modal-body">
    <ng-container *ngIf="!mostrarItens">
      <form [formGroup]="form">
        <div class="row">
          <div class="form-group col-sm-12">
            <label for="modal-importar-nfe-search">Pesquisar</label>
            <input id="modal-importar-nfe-search" type="text" class="form-control" formControlName="search" placeholder="Pesquisar">
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <app-dim-datatable
            *ngIf="(notas?.items && notas.items.length > 0) || loading.notas"
            [loading]="loading.notas"
            [selectable]="true"
            [selecionados]="notasSelecionadas"
            [columns]="colunas"
            [data]="notas?.items"
            [totalItems]="notas?.totalItems"
            [currentPage]="notas?.pageIndex"
            [rowsOnPage]="10"
            (sorting)="onSort($event)"
            (pageChanged)="onSort($event)"
          ></app-dim-datatable>
          <p
            *ngIf="!((notas?.items && notas.items.length > 0) || loading.notas)"
            class="mt-2 mb-4"
          >
            Nenhuma nota de remessa encontrada.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button
            class="btn btn-primary"
            (click)="carregarItens()"
            [disabled]="loading.itens || notasSelecionadas.length === 0"
          >
            <app-loading [loading]="loading.itens" texto="Próximo"></app-loading>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="mostrarItens">
      <form [formGroup]="formBuscaItens">
        <div class="row">
          <div class="form-group col-sm-12">
            <label for="modal-importar-itens-search">Pesquisar</label>
            <input id="modal-importar-itens-search" type="text" class="form-control" formControlName="search" placeholder="Pesquisar">
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <app-dim-datatable
            [loading]="loading.itens"
            [selectable]="true"
            [selecionados]="itensSelecionados"
            [columns]="colunasItens"
            [data]="itensFiltrados"
            [showPagination]="false"
            [serverPaginated]="false"
            [rowsOnPage]="9999"
          ></app-dim-datatable>
          <p *ngIf="!itens || itens.length === 0" class="mt-2 mb-4">
            Nenhum item com saldo encontrado.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button
            class="btn btn-primary"
            (click)="importarItens()"
            [disabled]="loading.importarItens || itensSelecionados.length === 0"
          >
            <app-loading
              icone="ti ti-new-window"
              [loading]="loading.importarItens"
              texto="Importar itens"
            ></app-loading>
          </button>
          <button class="btn btn-info btn-outline-info" (click)="voltar()" [disabled]="loading.importarItens">
            Voltar
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</app-temp-modal-cadastro>
